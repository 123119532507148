<template>
  <div>
    <div id="btn-open-modal-quiz" @click="openModal" class="btn-openModal">
      <p class="text">Clique e adicione o Questionário</p>
    </div>

    <div class="spaceInputs"></div>
    <b-form-group
      v-if="checkData"
      label="Questionário da Aula"
      label-for="name-aula"
      id="step15"
    >
      <div
        class="card mt-3 mb-3 spaceModuleCardQuiz"
        v-for="(question, index) in dataQuiz"
        :key="index"
      >
        <div v-if="question.text" class=" card-body row ">
          <div
            class="col-10 container-question-text "
            style="align-self: center;"
            @click="showOptions(question)"
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.09 8C8.3251 7.33167 8.78915 6.76811 9.39995 6.40913C10.0108 6.05016 10.7289 5.91894 11.4272 6.03871C12.1255 6.15849 12.7588 6.52152 13.2151 7.06353C13.6713 7.60553 13.9211 8.29152 13.92 9C13.92 11 10.92 12 10.92 12M11 16H11.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
                stroke="#002363"
                stroke-width="1.7"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <small class="ml-3">{{
              question.text.length >= 65
                ? question.text.slice(0, 65) + "..."
                : question.text
            }}</small>
          </div>
          <div class="col-2">
            <div class="container-actions-question">
              <svg
                class="action"
                width="19"
                height="5"
                viewBox="0 0 19 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.83667 3.83643C10.389 3.83643 10.8367 3.38871 10.8367 2.83643C10.8367 2.28414 10.389 1.83643 9.83667 1.83643C9.28439 1.83643 8.83667 2.28414 8.83667 2.83643C8.83667 3.38871 9.28439 3.83643 9.83667 3.83643Z"
                  stroke="#C4C4C4"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.8367 3.83643C17.389 3.83643 17.8367 3.38871 17.8367 2.83643C17.8367 2.28414 17.389 1.83643 16.8367 1.83643C16.2844 1.83643 15.8367 2.28414 15.8367 2.83643C15.8367 3.38871 16.2844 3.83643 16.8367 3.83643Z"
                  stroke="#C4C4C4"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.83667 3.83643C3.38895 3.83643 3.83667 3.38871 3.83667 2.83643C3.83667 2.28414 3.38895 1.83643 2.83667 1.83643C2.28439 1.83643 1.83667 2.28414 1.83667 2.83643C1.83667 3.38871 2.28439 3.83643 2.83667 3.83643Z"
                  stroke="#C4C4C4"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <b-dropdown dropright text="" class="btn-drop">
                <b-dropdown-item @click="viewOptions(question)"
                  >Editar</b-dropdown-item
                >
                <b-dropdown-item @click="alertRemove(question)"
                  >Deletar</b-dropdown-item
                >
                <!--                @click="removeQuestion(question)"-->
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </b-form-group>
    <ModalQuiz :inEdit="inEdit" @addedQuestion="addedQuestion" />

    <ModalViewQuiz
      @addedQuestion="updateQuestion"
      :inEdit="inEdit"
      :dataQuestion="dataQuestion"
    />

    <b-modal
      id="modal-view-quiz-options"
      hide-footer
      size="lg"
      class="animationOpacity"
      @close="closeModalOptions"
      no-close-on-backdrop
    >
      <b-row class="quiz-options">
        <b-col cols="12" md="10" class="mx-auto pt-1 mb-5">
          <div class="d-block">
            <div class="container-title-header">
              <div class="title">Questionário</div>
              <div class="lineDiv mt-3 mb-3 col-md-12 col-12"></div>
            </div>
            <draggable
              class="list-group"
              ghost-class="ghost"
              @end="onEnd"
              :disabled="!inEdit"
            >
              <div
                class="card card-body mt-2 container-question-text spaceModuleCardQuiz d-inline-block w-100 container-options"
                v-for="(options, index) in dataOptions.answers"
                :key="index"
                :data-id="options.id"
                :data-index="index"
                :class="{ 'cursor-grabbing': inEdit }"
              >
                <div
                  class="option-number d-inline-block"
                  :class="{ active: options.is_correct }"
                >
                  <span style="color: black">{{ index + 1 }}</span>
                </div>
                <div class="col-10 d-inline-block">
                  <small>{{ options.text }}</small>
                </div>
              </div>
            </draggable>
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <ModalDeletarAlternativa
      :question="question"
      @removeQuestion="removeQuestion"
    />
  </div>
</template>

<script>
import ModalQuiz from "@/components/ModalQuiz.vue";
import ModalViewQuiz from "@/components/ModalViewQuiz.vue";
import notify from "@/services/libs/notificacao";
import CourseService from "@/services/resources/CourseService";
import ModalDeletarAlternativa from "@/components/ModalDeletarAlternativa.vue";

import draggable from "vuedraggable";

const serviceCourse = CourseService.build();

export default {
  name: "Quiz",
  components: {
    ModalQuiz,
    ModalViewQuiz,
    draggable,
    ModalDeletarAlternativa,
  },
  props: ["questions", "inEdit"],
  data() {
    return {
      dataQuiz: this.questions,
      dataQuestion: {},
      dataOptions: { answers: [] },
      question: {},
    };
  },
  watch: {
    questions: function() {
      this.dataQuiz = this.questions;
    },
  },
  computed: {
    checkData() {
      return (
        this.dataQuiz[0] ||
        (this.dataQuiz.questions && this.dataQuiz.questions.length)
      );
    },
  },
  methods: {
    alertRemove(question) {
      this.question = question;
      this.$bvModal.show("modal-delete-question");
    },
    closeModalOptions() {
      this.$emit("closeModalOptions", this.question.lesson_id);
    },
    onEnd: function(e) {
      var array = [];
      var lessons = document.querySelectorAll(".container-options");

      for (let i = 0; i < lessons.length; i++) {
        const option = lessons[i].dataset;
        array.push(parseInt(option.id));
      }

      const cursoId = this.$route.params.curso;
      const moduleId = this.$route.params.module;

      let data = {
        id:
          cursoId +
          "/module/" +
          moduleId +
          "/lesson/" +
          this.question.lesson_id +
          "/question/" +
          this.question.id +
          "/answer/order",
        array,
      };
      this.$root.$emit("loadOn");
      serviceCourse
        .postIDArray(data)
        .then((resp) => {
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    showOptions(question) {
      this.dataOptions = question;
      this.question = question;
      this.$bvModal.show("modal-view-quiz-options");
    },
    async removeQuestion(question) {
      if (question.id) {
        await serviceCourse
          .delete(
            `${question.course_id}/module/${question.module_id}/lesson/${question.lesson_id}/question/${question.id}`
          )
          .then((resp) => {
            if (resp) {
              notify("sucesso", "Questão removida com sucesso!");
            }
          })
          .catch((e) => {
            //console.log(e)
            notify("erro", "Falha ao remover questão!");
            return false;
          });
      }

      let data = [];
      this.dataQuiz.forEach((item, index) => {
        if (item.id === question.id) {
          delete this.dataQuiz[index];
        } else {
          data.push(item);
        }
      });

      this.dataQuiz = data;
      this.$emit("removedQuestion", data);
    },
    openModal() {
      //console.log(this.questions)
      this.$bvModal.show("modal-quiz");
    },
    updateQuestion(dataQuestion) {
      this.$emit("addedQuestion", dataQuestion);
    },
    addedQuestion(dataQuestion) {
      if (this.inEdit) {
        let items = [];

        this.questions.forEach((item) => {
          if (item.text) {
            items.push(item);
          }
        });
        dataQuestion.questions.forEach((item) => {
          if (item.text) {
            items.push(item);
          }
        });
        this.dataQuiz = items;
      } else {
        this.dataQuiz = dataQuestion.questions;
      }
      this.$emit("addedQuestion", dataQuestion);
    },
    viewOptions(question) {
      //console.log('question', question)
      this.dataQuestion = question;
      this.$bvModal.show("modal-view-quiz");
    },
  },
};
</script>
<style lang="scss">
#modal-view-quiz-options___BV_modal_header_ {
  border-bottom: 0;
}
.container-actions-question {
  display: flex;
  align-items: center;
  svg {
    position: relative;
    left: 24px;
    z-index: 2;
  }
  div {
    button.btn.dropdown-toggle.btn-secondary,
    button.btn.dropdown-toggle.btn-secondary:hover,
    button.btn.dropdown-toggle.btn-secondary:focus,
    button.btn.dropdown-toggle.btn-secondary:active,
    button.btn.dropdown-toggle.btn-secondary:focus-within,
    button.btn.dropdown-toggle.btn-secondary:focus-visible {
      background: rgba(255, 255, 255, 0) !important;
      border: rgba(0, 0, 0, 0) !important;
      box-shadow: none !important;
      z-index: 9;
      color: #303e3e00 !important;
      top: -3px;
    }

    a.dropdown-item {
      font-family: Inter !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 14px !important;
      color: #333 !important;
    }

    .dropdown-item:hover,
    .dropdown-item:focus {
      color: #333 !important;
      text-decoration: none !important;
      background-color: #fff !important;
    }
  }
}
</style>
<style scoped lang="scss">
.spaceModuleCardQuiz {
  background: var(--backgroundcolor);
  border: 0.5px solid var(--bordercolor);
  box-sizing: border-box;
  border-radius: 30px;
  cursor: pointer;
}
/**/
.spaceModuleCardQuiz > .text > span {
  color: var(--fontcolor) !important;
}

.spaceModuleCardQuiz > div > div > small {
  color: var(--fontcolor) !important;
}

.spaceModuleCardQuiz > .d-inline-block {
  color: var(--fontcolor) !important;
}

.cursor-grabbing {
  cursor: grabbing;
}

.option-number.active {
  background-color: #002363 !important;
  color: #fff;
}

.option-number > span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}
.option-number {
  width: 40px;
  height: 40px;
  background: #ededf0;
  border-radius: 30px;
  font-weight: 600;
  vertical-align: top;
  text-align-last: center !important;
}

.container-question-text > small {
  font-weight: 600;
}

.btn-openModal {
  background: var(--maincolortrans);
  border: 2px dashed var(--maincolor);
  box-sizing: border-box;
  border-radius: 30px;
  height: 55px;
  cursor: pointer;
}
.text {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--maincolor);
  display: flex;
  justify-content: center;
  margin-top: 17px;
}
</style>
