var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{staticClass:"animationOpacity",attrs:{"id":"modal-quiz","hide-footer":"","size":"lg","no-close-on-backdrop":""}},[_c('b-row',{staticClass:"quiz"},[_c('b-col',{staticClass:"mx-auto pt-1 mb-5",attrs:{"cols":"12","md":"10"}},[_c('div',{staticClass:"d-block"},[_c('div',{staticClass:"container-title-header"},[_c('div',{staticClass:"title"},[_vm._v("Questionario")]),_c('div',{staticClass:"lineDiv mt-3 mb-3 col-md-12 col-12"})]),_c('div',[_c('div',{staticClass:"mt-3"},[_c('label',[_vm._v("Qual e a questão?")]),_c('b-form-textarea',{attrs:{"id":"textarea","placeholder":"Questão","rows":"3","max-rows":"6"},model:{value:(_vm.quiz.questions[_vm.indexQuestion].text),callback:function ($$v) {_vm.$set(_vm.quiz.questions[_vm.indexQuestion], "text", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"quiz.questions[indexQuestion].text"}})],1),(
              _vm.quiz.questions[_vm.indexQuestion] &&
                _vm.quiz.questions[_vm.indexQuestion].text
            )?_c('button',{staticClass:"btn btn-add-options p-3 w-100 mt-3",on:{"click":_vm.addAlternative}},[_vm._v(" Adicione alternativa ")]):_vm._e(),_vm._l((_vm.quiz.questions[_vm.indexQuestion].answers),function(item,index){return _c('div',{key:index,staticClass:"mt-3 container-options"},[_c('b-form-group',{attrs:{"label":'Alternativa ' + (index + 1)}},[_c('b-form-radio',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"name":"is_correct","title":"Alternativa correta?","value":false},on:{"change":function($event){return _vm.isCorrect(index)}}}),_c('div',{staticClass:"input-group mb-2 ml-0",staticStyle:{"width":"95%"}},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(
                    _vm.quiz.questions[_vm.indexQuestion].answers[index].text
                  ),expression:"\n                    quiz.questions[indexQuestion].answers[index].text\n                  ",modifiers:{"trim":true}}],staticClass:"form-control input-options",attrs:{"type":"text","placeholder":'Opção ' + (index + 1)},domProps:{"value":(
                    _vm.quiz.questions[_vm.indexQuestion].answers[index].text
                  )},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addAlternative.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.quiz.questions[_vm.indexQuestion].answers[index], "text", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"input-group-prepend",on:{"click":function($event){return _vm.removeOption(_vm.indexQuestion, index)}}},[_c('div',{staticClass:"input-group-text btn-remove-option"},[_c('button',{staticClass:"close",attrs:{"type":"button"}},[_vm._v("×")])])])])],1)],1)}),(_vm.activateToTerminate())?_c('button',{staticClass:"btn btn-finish p-3 w-100 mt-3",on:{"click":_vm.addedQuestion}},[_vm._v(" Finalizar ")]):_vm._e()],2)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }